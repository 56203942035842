<template>
  <div class="mt-5">
    <div>
      <v-data-table
        :expanded.sync="expanded"
        :headers="headers"
        :items="dataSource"
        :loading="loaded"
        :options.sync="options"
        :search="search"
        :server-items-length="totalItems"
        :single-expand="singleExpand"
        class="mt-5"
        item-key="uuid"
        show-expand
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="bg-expanded-row">
            <main class="detail-container-row">
              <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" sm="12" xl="12">
                  <div>
                    <div class="display-align-center principal-detail-row">
                      <v-icon color="tableHeader">mdi-counter</v-icon>
                      <span class="ml-1">Detalle</span>
                    </div>
                  </div>
                  <v-simple-table class="mt-3">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left"></th>
                        <th class="text-left"></th>
                        <th class="text-left"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(itemd, index) in item.orderSeries"
                          :key="index"
                      >
                        <td>{{ itemd.seriesType.name }}</td>
                        <td>{{ itemd.init || 'N/A'}} - {{itemd.end || 'N/A'}}</td>
                        <td>{{itemd.total.toLocaleString() || 'N/A'}}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </main>
          </td>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
            :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.type || 'N/A'}}</span>
            </td>
            <td>
              <span>{{ formatDate(item.createdAt) }}</span>
            </td>
            <td>
              <span>{{ getSeriesType(item.orderSeries) }}</span>
            </td>
            <td>
              <div class="display-align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="downloadExcel(item)"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                        :loading="selectedOrder.uuid === item.uuid"
                    >
                      <v-icon color="tableActions">
                        mdi-file-excel
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="editRow(item, 'CrudOrders', 'edit')"
                        class="mx-2"
                        v-if="hasAccess('/orders', 'POST')"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                    >
                      <v-icon color="tableActions">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar orden</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="deleteRow(item, 'CrudOrders', 'delete')"
                        class="mx-2"
                        v-if="hasAccess('/orders', 'DELETE')"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                    >
                      <v-icon color="tableActions">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar orden</span>
                </v-tooltip>
              </div>
            </td>
            <td @click="expand(!isExpanded)">
              <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
      :dialog="dialog"
      :iteName="'Orden'"
      :type="type"
      :message="message"
      @cancelAction="dialog = false"
      @closeAction="closeAction"
      @deleteAction="deleteAction"
    ></ActionPopUp>
<!--    <div>
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>-->
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import moment from "moment";
import customStore from "../../services/customStore";

export default {
  name: "ordersResume",
  components: { ActionPopUp },
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      module: "orders",
      label: "Escribe el nombre o la secuencia",
      overlay:false,
      message:"",
      selectedOrder:{}
    };
  },
  methods: {
    afterProcess() {
      this.orderParam = '-createdAt'
      this.headers = [
        {
          text: "Tipo de orden",
          sortable: true,
          value: "type",
        },
        {
          text: "Fecha",
          sortable: true,
          value: "createdAt",
        },
        {
          text: "Tipo de series",
          sortable: false,
          value: "",
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" },
      ];
    },
    getSeriesType(seriesType = []) {
      if (!seriesType?.length) {
        return ''
      }
     const allSeriesTypes = seriesType.map((item)=>item?.seriesType.name)
      return allSeriesTypes.join()
    },
    formatDate(createdAtDate = '') {
      return moment(createdAtDate).format('DD/MM/YYYY HH:mm')
    },
    async downloadExcel(item) {
      try {
        this.selectedOrder = item
        this.overlay = true
        await customStore.getPdfOrders(item.uuid)
        this.overlay = false
        this.selectedOrder = {}
      }catch(error) {
        this.overlay = false
        this.message = error?.response?.data?.details || ''
        this.dialog = true
        this.selectedOrder = {}
      }
    }
  },
};
</script>

<style scoped></style>
