import Axios from "axios";
import {urlFull} from "@/services/config";

const customStoreService = {
  getReportsByModule(module, startDate, endDate, limit) {
    return Axios.get(
      urlFull(
        `/${module}?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
      )
    ).then((res) => res.data);
  },
  getDataByModule(module, search = "", page = 0, itemsPerPage = 10, startDate = "", endDate = "", embedded = "", order, repositionOrderId) {
    return Axios.get(
      urlFull(
        `/${module}?search=${search}&page=${page}&pageSize=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&embedded=${embedded}${order ? `&order=${order}` : ''} 
        ${repositionOrderId ? `&repositionOrderId=${repositionOrderId}` : ''}`
      )
    ).then((res) => res.data);
  },
  getDataByProperty(module, property = '', elementId = null) {
    if (!elementId) {
      return Promise.resolve()
    }
    return Axios.get(
      urlFull(
        `/${module}?${property}=${elementId}`
      )
    ).then((res) => res.data);
  },
  deleteByModule(module, data) {
    return Axios.delete(urlFull(`/${module}/${data.uuid}`)).then(
      (res) => res.data
    );
  },
  getDataCrudByModule(module, uuid) {
    return Axios.get(urlFull(`/${module}/${uuid}`)).then((res) => res.data);
  },
  getDataListByModule(module) {
    return Axios.get(urlFull(`/${module}`)).then((res) => res.data);
  },
  getDataCrudByModuleAndParam(module, uuid, param) {
    return Axios.get(urlFull(`/${module}?${param}=${uuid}`)).then((res) => res.data);
  },
  postDataByModule(module, data) {
    return Axios.post(urlFull(`/${module}`), data).then((res) => res.data);
  },
  postImageByOwner(ownerId, formData) {
    return Axios.post(urlFull(`/storage/owner/${ownerId}`), formData).then(
      (res) => res.data
    );
  },
  getImagesByOwner(ownerId) {
    return Axios.get(urlFull(`/storage/owner/${ownerId}`)).then(
      (res) => res.data
    );
  },
  deleteFileByOwner(ownerId, data) {
    return Axios.delete(urlFull(`/storage/owner/${ownerId}/${data.name}`)).then(
      (res) => res.data
    );
  },
  postAdditionalDriver(data) {
    return Axios.post(urlFull(`/additional-drivers`), data).then(
      (res) => res.data
    );
  },
  postDataByModuleAndUrl(module, uuid) {
    return Axios.post(urlFull(`/${module}/${uuid}`)).then(
      (res) => res.data
    );
  },
  getPdfRegister(uuid, name) {
    return Axios.get(urlFull(`/patients/pdf/${uuid}`), {responseType: 'blob'})
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Hubo un error al descargar el archivo:", error);
      });
  },
  getPdfOrders(uuid) {
    return Axios.get(urlFull(`/orders/excel/${uuid}`), {responseType: 'blob'})
      .then((res) => {
        let name = `${uuid}.xlsx`
        const fileName = res.headers['content-disposition']
        if (fileName) {
          const realName = fileName.split(' ')[1]
          if (realName) {
            name = realName.replace(/^filename=/, '');
          }
        }
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Hubo un error al descargar el archivo:", error);
      });
  },
  getExcelRepositionOrders(data) {
    return Axios.post(urlFull(`/reposition-orders/${data?.uuid}/download`), {}, {responseType: 'blob'})
      .then((res) => {
        let name = `archivo.xlsx`
        const fileName = res.headers['content-disposition']
        if (fileName) {
          const realName = fileName.split(' ')[1]
          if (realName) {
            name = realName.replace(/^filename=/, '');
          }
        }
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Hubo un error al descargar el archivo:", error);
      });
  },
  postPlatesExcelFile(data) {
    return Axios.post(urlFull(`/calculator/calculate-download-excel`), data, {responseType: 'blob'})
      .then((res) => {
        let name = `archivo.xlsx`
        const fileName = res.headers['content-disposition']
        if (fileName) {
          const realName = fileName.split(' ')[1]
          if (realName) {
            name = realName.replace(/^filename=/, '');
          }
        }
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Hubo un error al descargar el archivo:", error);
      });
  },
  postPlatesExcel(data) {
    return Axios.post(urlFull(`/calculator/calculate-download-excel`), data).then((res) => res.data);
  },
  postCalculatorData(module, data) {
    return Axios.post(urlFull(`/${module}`), data).then(
      (res) => res.data
    );
  },
  postUploadFile (module, formData) {
    for (var [key, value] of formData.entries()) {
      console.log(key, value, '333');
    }
    return Axios.post(urlFull(`/${module}`), formData,).then((res) => res.data)
  }
};

export default customStoreService;
