<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        :validBtn="hasAccess('/orders', 'POST')"
        principal-btn-title="Nueva reposición"
        subtitle="Crea y visualiza tus reposiciones"
        title="Reposiciones"
        @setDefaultPrimaryAction="createNewStore"
    />
    <RepositionOrdersResume/>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs"
import TopInfoAction from "../components/TopInfoAction.vue"
import moduleMixin from "@/mixins/moduleMixin"
import RepositionOrdersResume from "@/components/repositionOrders/RepositionOrdersResume.vue"

export default {
  name: "reposition-orders",
  mixins: [moduleMixin],
  components: {RepositionOrdersResume, TopInfoAction, Breadcrumbs},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Reposiciones",
        disabled: true,
        path: "/reposition-orders",
      },
    ],
  }),
  methods: {
    createNewStore() {
      this.$router.push({
        name: "CrudRepositionOrders",
        params: {crudAction: "create"},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
