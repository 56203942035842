<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        :valid-btn="validFormAllow"
        principal-btn-title="Guardar"
        secondary-btn-title="Cancelar"
        subtitle="Llena el formulario y presiona click en el boton de guardar"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    />
    <div v-if="loaded">
      <RepositionOrdersForm
          v-if="!currentElement.uuid"
          :current-element="currentElement"
          @validFormInput="validFormInput"
      />
      <RepositionOrdersDetailResume v-else :id="currentElement.uuid"/>
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Orden'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue"
import TopInfoAction from "../components/TopInfoAction.vue"
import SkeletonLoaded from "@/components/SkeletonLoaded.vue"
import ActionPopUp from "@/components/ActionPopUp.vue"
import CRUDMixin from "@/mixins/CRUDMixin"
import customStore from "../services/customStore"
import RepositionOrdersForm from "@/components/repositionOrders/RepositionOrdersForm.vue";
import RepositionOrdersDetailResume from "@/components/repositionOrders/RepositionOrdersDetailResume.vue";

export default {
  name: "CRUDRepositionOrders",
  mixins: [CRUDMixin],
  props: {
    pathBack: {type: String, default: "reposition-orders"},
  },
  components: {
    RepositionOrdersDetailResume,
    RepositionOrdersForm,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Reposiciones",
          disabled: false,
          path: "/reposition-orders",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nueva"
                  : this.$route.params.crudAction === "edit"
                      ? "Visualizar"
                      : this.$route.params.crudAction) || ""
          } reposición`,
          disabled: true,
          path: "/orders",
        },
      ],
      currentElement: {
        repositionSeries:[],
      },
      module: "reposition-orders",
      titleCreate: "Crea una nueva reposición",
      titleEdit: "Visualizar reposición",
      message:'',
    };
  },

  computed:{
    validFormAllow() {
      if (this.currentElement.uuid) {
        return false
      }
      if (!this.currentElement) {
        return false;
      }
      return this.currentElement?.repositionSeries?.length > 0;
    }
  },

  methods: {
    validFormInput(e) {
      this.validForm = e;
    },
   async beforeFillData() {
      try {
       const res = await customStore.getDataListByModule(
            `reposition-series?repositionOrderId=${this.currentElement.uuid}`,
        )
       if (res?.data?.length) {
         this.currentElement.repositionSeries = res.data
       }else {
         this.currentElement.repositionSeries = []
       }
      }catch(error) {
        this.type = "error";
        this.dialog = true;
        this.message = error?.response?.data?.details || ''
      }
    },
  },
};
</script>

<style scoped></style>
