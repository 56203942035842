<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-form v-model="validForm" @input="inputForm">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col v-if="!currentElement.uuid" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <title-info-card icon="mdi-counter" textTitle="Tipo de series">
                  <template #contentCard>
                    <v-row class="mt-2" >
                      <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                        <div>
                          <label class="input-label" for="StartDate"
                          >Tipo de series<span class="required-field-form">*</span></label
                          >
                          <v-autocomplete v-model="seriesTypeId"
                                          :search-input.sync="search"
                                          :items="availableTypeSeries"
                                          clearable
                                          item-value="uuid"
                                          :item-text="formatItemText"
                                          @change="getReferenceByUUID"
                                          color="geckoInput"
                                          hide-details
                                          outlined placeholder="Ex: Servicio particular"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <div>
                          <label class="input-label" for="name">Inicio de la serie {{ reference }}<span
                              class="required-field-form">*</span></label>
                          <v-text-field
                              v-model="init"
                              v-mask="reference"
                              :readonly="reference === ''"
                              hide-details
                              outlined
                              color="backofficeInput"
                              :placeholder="reference ? `Ejemplo: ${reference}` : 'Ejemplo: 7MNS35' "
                              @input="(value) => init = value.replace(/([a-z])/g, (match) => match.toUpperCase())"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <div class="space-right-input">
                          <label class="input-label" for="state">Final de la serie {{ reference }}</label>
                          <v-text-field
                              v-model="end"
                              v-mask="reference"
                              :readonly="reference === ''"
                              hide-details
                              outlined
                              color="backofficeInput"
                              :placeholder="reference ? `Ejemplo: ${reference}` : 'Ejemplo: 7MNS35' "
                              @input="(value) => end = value.replace(/([a-z])/g, (match) => match.toUpperCase())"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <div class="space-left-input">
                          <label class="input-label" for="city">Total</label>
                          <v-text-field
                              v-model="total"
                              type="number"
                              :readonly="reference === ''"
                              hide-details
                              outlined
                              color="backofficeInput"
                              placeholder="Ejemplo: 65"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <div>
                          <label class="input-label" for="city">Fecha de reposición</label>
                          <v-text-field
                              v-model="repositionDate"
                              type="text"
                              :readonly="reference === ''"
                              hide-details
                              outlined
                              color="backofficeInput"
                              placeholder="26/04/25"
                              maxlength="8"
                              @input="formatDate"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-btn :disabled="!validBtn" class="primary btn-active" rounded @click="addOrder">Agregar</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </title-info-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col class="mb-5" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-data-table
          :headers="ordersHeader"
          :items="currentElement.repositionSeries"
          class="mt-5"
          :item-key="'uuid' ? 'uuid' : 'init' "
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ getSeriesTypeById(item.seriesTypeId )|| 'N/A'}}</span>
            </td>
            <td>
              <span>{{ item.init || 'N/A'}}</span>
            </td>
            <td>
              <span>{{ item.end  || ''}}</span>
            </td>
            <td>
              <span>{{ item.repositionDate || 'N/A' }}</span>
            </td>
            <td>
              <span>{{ item.total || 'N/A' }}</span>
            </td>
            <td>
              <div v-if="!currentElement.uuid" class="display-align-center">
                <v-icon
                    class="ml-2 cursor-pointer-action"
                    color="tableActions"
                    @click="deleteRow(item)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Reposicioón'"
        :type="typeDialog"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import {required} from "../../utils/validationRule";
import customStoreService from "../../services/customStore";
import customStore from "@/services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";

export default {
  name: "RepositionOrdersForm",
  components: {ActionPopUp, TitleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      responseValid: {},
      dialog: false,
      typeDialog: 'error',
      message: '',
      required,
      validForm: false,
      page: 0,
      itemsPerPage: 500,
      search:'',
      availableTypeSeries:[],
      seriesTypeId:'',
      reference:'',
      init:'',
      end:null,
      total:null,
      repositionDate:null,
      ordersTypes:['ALMACEN', 'PROVEEDOR'],
      ordersHeader: [
        {
          text: "Tipo de series",
          sortable: false,
          value: "seriesTypeId",
        },
        {
          text: "Inicio",
          sortable: false,
          value: "init",
        },
        {
          text: "Fin",
          sortable: false,
          value: "end",
        },
        {
          text: "Fecha de reposición",
          sortable: false,
          value: "end",
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
        },
        { text: "", value: "actions" },
      ]
    }
  },
  computed: {
    validBtn(){
      return this.seriesTypeId && this.init && this.repositionDate && (this.end || this.total)
    }
  },
  mounted() {
    this.searchTypeSerieAvailable()
  },
  methods: {
    closeAction() {
      this.dialog = false
    },
    async searchTypeSerieAvailable(search) {
      const {data} = await customStoreService.getDataByModule(
          'series-types',
          search === null ? '' : search,
          this.page,
          this.itemsPerPage,
          '',
          '',
          '',
          ''
      );
      if (data?.length) {
        this.availableTypeSeries = data
      } else {
        this.availableTypeSeries = []
      }
    },
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    formatItemText(typeSerie) {
      return `${typeSerie?.name}`
    },
    getReferenceByUUID(evt) {
      if (evt) {
        const response = this.availableTypeSeries.find((item) => item.uuid === evt)
        if (response) {
          this.reference = response.mask
        } else {
          this.reference = ''
        }
      } else {
        this.reference = ''
      }
    },
    deleteRow(item) {
      const index = this.currentElement.repositionSeries.findIndex((itemRes)=> itemRes.id === item.id)
      if (index !== -1) {
        this.currentElement.repositionSeries.splice(index, 1)
      }
    },
    async validateBeforeSave(data) {
      console.log('Entro a validate');
      try {
       const res = await customStore.postDataByModule('reposition-series/validate', data)
        this.responseValid = res
      } catch (error) {
        console.error("Error en validateBeforeSave:", error);
        throw error; // Lanza el error original sin modificarlo
      }
    },
    async addOrder() {
      try {
        const data = {
          init: this.init,
          repositionDate: this.repositionDate,
          end: (this.end === "" || this.end === null) ? null : this.end,
          total: (this.total === "" || this.total === null) ? null : parseInt(this.total, 10),
          seriesTypeId: this.seriesTypeId
        }
        await this.validateBeforeSave(data)
        const dataResponse = {
          init: this.init,
          repositionDate: this.repositionDate,
          end :this.responseValid?.end,
          total: parseInt(this.responseValid?.total, 10),
          seriesTypeId: this.seriesTypeId
        }
        this.currentElement.repositionSeries.push({
          id: this.generateUUID(),
          ...dataResponse
        })
        this.responseValid = {}
        this.seriesTypeId = ''
        this.init = ''
        this.repositionDate = null
        this.end = null
        this.total = null
        this.reference = ''
      }catch(error) {
        this.typeDialog = 'error'
        this.message = error?.response?.data?.details || 'Error al guardar la serie'
        this.dialog = true
      }
    },
    getSeriesTypeById(id) {
      if (this.availableTypeSeries?.length) {
        return this.availableTypeSeries.find(({uuid})=>uuid === id).name ?? 'N/A'
      }
      return 'N/A'
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    formatDate(event) {
      if (/[a-zA-Z]/.test(event)) {
        this.$nextTick(() => {
          this.repositionDate = event.replace(/[a-zA-Z]/g, '')
        })
        return
      }
      if (isNaN(parseInt(event))){
        this.$nextTick(()=> {
          this.repositionDate = event.replace(/[a-zA-Z]/g, "")
        })
        return
      }
      const newDate = event.replace(/\D/g, '')
      let date = newDate
      if (date.length >= 2) {
        date = date.slice(0, 2) + '/' + date.slice(2)
      }
      if (date.length >= 5) {
        date = date.slice(0, 5) + '/' + date.slice(5, 7)
      }
      if (date.length > 7) {
        date = date.slice(0, 7)
      }
      this.repositionDate = date
    }
  }
}
</script>

<style scoped>

</style>
