<template>
  <div class="mt-5">
    <div>
      <v-data-table
          :expanded.sync="expanded"
          :headers="headers"
          :items="dataSource"
          :loading="loaded"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          :single-expand="singleExpand"
          class="mt-5"
          item-key="uuid"
          :show-expand="false"
      >

        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span>{{ getSeriesTypeById(item.seriesTypeId )|| 'N/A'}}</span>
            </td>
            <td>
              <span>{{ item.init || 'N/A'}}</span>
            </td>
            <td>
              <span>{{ item.end  || ''}}</span>
            </td>
            <td>
              <span>{{ item.repositionDate || 'N/A' }}</span>
            </td>
            <td>
              <span>{{ item.total || 'N/A' }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Reposición'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        :show-text-area-log="showTextArea"
        :log="log"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
    <!--    <div>
          <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
        </div>-->
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import customStoreService from "../../services/customStore";

export default {
  name: "RepositionOrdersDetailResume",
  components: {ActionPopUp},
  props: {id:{type: String, default: ''}},
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      showTextArea: false,
      log: '',
      file: null,
      module: `reposition-series`,
      repositionOrderId: this.id,
      label: "Escribe el nombre o la secuencia",
      overlay: false,
      message: "",
      selectedOrder: {},
      availableTypeSeries:[]
    };
  },
  async created() {
    await this.searchTypeSerieAvailable()
  },
  methods: {

    async searchTypeSerieAvailable(search) {
      const {data} = await customStoreService.getDataByModule(
          'series-types',
          search === null ? '' : search,
          this.page,
          this.itemsPerPage,
          '',
          '',
          '',
          ''
      );
      if (data?.length) {
        this.availableTypeSeries = data
      } else {
        this.availableTypeSeries = []
      }
    },

    afterProcess() {
      this.orderParam = '-createdAt'
      this.headers = [
        {
          text: "Tipo de series",
          sortable: false,
          value: "seriesTypeId",
        },
        {
          text: "Inicio",
          sortable: false,
          value: "init",
        },
        {
          text: "Fin",
          sortable: false,
          value: "end",
        },
        {
          text: "Fecha de reposición",
          sortable: false,
          value: "end",
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
        },
        { text: "", value: "actions" },
      ]
    },
    getSeriesTypeById(id) {
      if (this.availableTypeSeries?.length) {
        return this.availableTypeSeries.find(({uuid})=>uuid === id).name ?? 'N/A'
      }
      return 'N/A'
    },
  },
};
</script>

<style scoped></style>
