<template>
  <div class="mt-5">
    <v-row>
      <v-col class="d-flex justify-end mt-0 pt-0" cols="12" lg="12" md="12" sm="12" xl="12">
        <v-btn class="primary" rounded @click="triggerFileInput">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
            </template>
            <span>Subir archivo XLS</span>
          </v-tooltip>
          Nuevo por archivo
        </v-btn>
        <input
            type="file"
            ref="fileInput"
            style="display: none"
            accept=".xls,.xlsx"
            @change="uploadFile"
        />
      </v-col>
    </v-row>
    <div>
      <v-data-table
          :expanded.sync="expanded"
          :headers="headers"
          :items="dataSource"
          :loading="loaded"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          :single-expand="singleExpand"
          class="mt-5"
          item-key="uuid"
          :show-expand="false"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="bg-expanded-row">
            <main class="detail-container-row">
              <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" sm="12" xl="12">
                  <div>
                    <div class="display-align-center principal-detail-row">
                      <v-icon color="tableHeader">mdi-counter</v-icon>
                      <span class="ml-1">Detalle</span>
                    </div>
                  </div>
                  <v-simple-table class="mt-3">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left"></th>
                        <th class="text-left"></th>
                        <th class="text-left"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(itemd, index) in item.orderSeries"
                          :key="index"
                      >
                        <td>{{ itemd.seriesType.name }}</td>
                        <td>{{ itemd.init || 'N/A' }} - {{ itemd.end || 'N/A' }}</td>
                        <td>{{ itemd.total.toLocaleString() || 'N/A' }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </main>
          </td>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ formatDate(item.createdAt) }}</span>
            </td>
            <td>
              <span>{{ getSeriesType(item.repositionSeries) }}</span>
            </td>
            <td>
              <div class="display-align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="downloadExcel(item)"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                        :loading="selectedOrder.uuid === item.uuid"
                    >
                      <v-icon color="tableActions">
                        mdi-file-excel
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="editRow(item, 'CrudRepositionOrders', 'edit')"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                    >
                      <v-icon color="tableActions">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar orden</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="deleteRow(item, 'CrudRepositionOrders', 'delete')"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                    >
                      <v-icon color="tableActions">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar orden</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Reposición'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        :show-text-area-log="showTextArea"
        :log="log"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
    <!--    <div>
          <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
        </div>-->
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import moment from "moment";
import customStore from "../../services/customStore";

export default {
  name: "RepositionOrdersResume",
  components: {ActionPopUp},
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      showTextArea: false,
      log: '',
      file: null,
      module: "reposition-orders",
      label: "Escribe el nombre o la secuencia",
      overlay: false,
      message: "",
      selectedOrder: {}
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click()
    },

    async uploadFile(event) {
      this.showTextArea = false
      const selectedFile = event.target.files[0]
      const formData = new FormData()
      formData.append("file", selectedFile)
      if (!selectedFile) return

      // Verificar que sea Excel
      const validExtensions = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ];
      if (!validExtensions.includes(selectedFile.type)) {
        alert("Solo se permiten archivos .xls o .xlsx")
        return
      }

      try {
        await customStore.postUploadFile("reposition-orders/upload", formData)
        await this.loadData()
        this.$refs.fileInput.value = null
      } catch (error) {
        this.showTextArea = true
        this.dialog = true
        this.type = 'error'
        this.log = error?.response?.data?.details
        if (!this.log) {
          this.showTextArea = false
        }
        this.message = 'Error al subir el archivo'
        this.$refs.fileInput.value = null
      }
    },


    afterProcess() {
      this.orderParam = '-createdAt'
      this.headers = [
        {
          text: "Fecha",
          sortable: true,
          value: "createdAt",
        },
        {
          text: "Tipo de series",
          sortable: false,
          value: "",
        },
        {text: "", value: "actions"},
      ];
    },
    getSeriesType(seriesType = []) {
      if (!seriesType?.length) {
        return ''
      }
      const allSeriesTypes = seriesType.map((item) => item?.seriesType.name)
      return [...new Set(allSeriesTypes)].join()
    },
    formatDate(createdAtDate = '') {
      return moment(createdAtDate).format('DD/MM/YYYY HH:mm')
    },
    async downloadExcel(item) {
      const data = {
        uuid: item.uuid
      }
      try {
        this.selectedOrder = item
        this.overlay = true
        await customStore.getExcelRepositionOrders(data)
        this.overlay = false
        this.selectedOrder = {}
      } catch (error) {
        this.overlay = false
        this.message = error?.response?.data?.details || ''
        this.dialog = true
        this.selectedOrder = {}
      }
    }
  },
};
</script>

<style scoped></style>
